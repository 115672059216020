/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('anytime-alerts', {
      parent: 'root',
      url: '/anytime-alerts',
      template: '<ui-view/>',
      redirectTo: '.main',
      data: {
        permissions: {
          except: 'isPushNotificationEnabled',
          redirectTo: {
            isPushNotificationEnabled: 'aa-upgrade'
          }
        }
      },
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.apps.anytime-alerts" */ 'apps/anytime-alerts').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('anytime-alerts.intro', {
      url: '/intro',
      templateUrl:
        'apps/anytime-alerts/templates/acp-anytime-alerts-intro.ng.html',
      data: {
        permissions: {
          except: 'hasAlertsTermsAccepted',
          redirectTo: {
            hasAlertsTermsAccepted: 'anytime-alerts.main'
          }
        }
      },
      resolve: {
        $subaccountEnabledAccount: /*@ngInject */ function(nsPermissions) {
          return nsPermissions
            .requestPermission('subaccountEnabledAccount')
            .then(function(result) {
              if (result) {
                return result;
              }
            });
        }
      }
    })
    .state('anytime-alerts.main', {
      url: '',
      templateUrl: 'apps/anytime-alerts/templates/acp-anytime-alerts.ng.html',
      controller: 'AcpAnytimeAlertsSectionCtrl',
      data: {
        permissions: {
          only: 'hasAlertsTermsAccepted',
          except: 'showAnytimeAlertsMasterAccountManagement',
          redirectTo: {
            hasAlertsTermsAccepted: 'anytime-alerts.intro',
            showAnytimeAlertsMasterAccountManagement: 'anytime-alerts.manage',
          }
        }
      }
    })
    .state('anytime-alerts.manage', {
      url: '',
      templateUrl:
        'apps/anytime-alerts/templates/acp-anytime-alerts-master-account.ng.html',
      controller: 'AcpAnytimeAlertsSectionCtrl',
      data: {
        permissions: {
          only: 'showAnytimeAlertsMasterAccountManagement',
          redirectTo: {
            showAnytimeAlertsMasterAccountManagement: 'anytime-alerts.intro'
          }
        }
      }
    });
}

export default states;
